import * as Yup from "yup"
import {
  CustomFieldVisibility,
  isCustomFieldVisible,
} from "@/consts/CustomFieldVisibility"
import { isValidDateFieldValue, isValidValue, validateDateFieldAgeFilter } from "@/utils/validators"
import libphonenumber from 'google-libphonenumber'
import { defaultCountries, parseCountry } from "react-international-phone"

export const phoneValidator = (excludeEmptyString = true) =>
  Yup.string().matches(/\d{3}-?\d{3}-?\d{4}/g, {
    excludeEmptyString,
    message: "Phone number must follow the pattern: XXX-XXX-XXXX",
  })

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export const checkIfDialCodeOnly = (phone) => {
  const plusStripped = phone?.replace('+', '')
  
  const countryCodeMatch = defaultCountries.filter(country => {
    return country[2] === plusStripped
  })
  return countryCodeMatch?.length > 0
}

export const validateIntlPhone = (phone) => {
  const isDialCodeOnly = checkIfDialCodeOnly(phone)
  if (isDialCodeOnly) return true
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(phone))
  } catch (error) {
    return false
  }
}

export const intlPhoneTest = async (value) => {
  if (!value) return true
  return await validateIntlPhone(value)
}

export const intlPhoneValidator = (message) => {
  Yup.string().test('isValidIntlPhone', message, (value) => {
    if (!value) {
      return true
    }

    try {
      return phoneUtil.isValidNumber(phoneUtil.parse(value))
    } catch (error) {
      // console.log(error)
      return false
    }

    return false
  })
}

export const emailValidator = () => Yup.string().email("Invalid email address")

export const passwordValidator = () => Yup.string().min(8)

export const passwordConfirmationValidator = () =>
  Yup.string().test(
    "passwordConfirmation",
    ({ value }) => (value?.length >= 8 ? "passwords must match" : " "),
    (val, context) => val === context.parent.password
  )

export const customFieldsValidator = (
  customQueueCfValue?: string,
  visibility?: keyof typeof CustomFieldVisibility,
) => {
  return Yup.array().of(
    Yup.object({
      value: Yup.mixed()
        .test("checkRequiredCf", "Required", (val, context) => {
          //* auto-validate non-required fields
          if (!context.parent.isRequired) {
            return true
          }

          if (
            //* auto-validate invisible fields (be sure to also not submit them)
            visibility &&
            !isCustomFieldVisible(context.parent.visibility, visibility)
          ) {
            return true
          }

          //* check value
          if (isValidValue(val)) return true

          if (context.parent.type === 'queueselect') {
            return true
          }

          return false
        })
        .test('checkDateCf', 'Invalid date', (val, context) => {
          if (context.parent.type !== 'date') {
            return true
          }
          if (!val) return true
          const isValidDate = isValidDateFieldValue(val)
          // when age limit setting is applied to a date field
          const isValidFilteredDate = validateDateFieldAgeFilter(val, context?.parent?.settings)
          return isValidDate && isValidFilteredDate
        })
        .when("type", {
          is: "email",
          then: Yup.string().email("Invalid email address"),
        })
        .test("checkNumber", "Only numeric values allowed", (val, context) =>
          context.parent.type === "number"
            ? Yup.number().isValidSync(val)
            : true
        ),
    })
  )
}

// Regex did not perform reliably with Yup, but this does
export const htmlTagFieldValidator = (value: string) => {
  if (value) {
    const matches = value.includes('<') ||
    value.includes('>') ||
    value.includes('&lt;') ||
    value.includes('&gt;') ||
    value.includes('&#') ||
    value.includes('"') ||
    value.includes('&quot;') ||
    value.includes(';') ||
    value.includes('&semi;')

    return !matches
  }
  return true
}
